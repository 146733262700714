/* eslint-disable max-lines, max-statements */

import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { IconButton, sizes, Text, useBrowserType, useTheme } from '@almond/ui';
import { formatPriceInCents } from '@almond/utils';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useVisitReasons } from '~modules/appointment';
import { ErrorMessage } from '~modules/errors';
import { calculateDiscount, calculateRenewalPrice, useCalculatePrice } from '~modules/payment';
import { useStripeProduct } from '~modules/product';
import { creditCardAtom, userAtom } from '~modules/state';

import { CodeEntryForm } from './CodeEntryForm';
import { LineItem } from './LineItem';
import { ProductIcon } from './ProductIcon';
import { useCheckCode } from './useCheckCode';

import themedStyles from './styles';

import type { ProductOut } from '@almond/api-types';
import type { PromotionCode, PromotionCodeNotApplicable, PromotionCodeNotFound } from '~types';

type SidePanelProps = {
  product: ProductOut;
  mobileRender: (
    content: React.JSX.Element,
    isLoading: boolean,
    isError: boolean,
    totalPrice: number
  ) => React.JSX.Element;
};

export const SidePanel: React.FC<SidePanelProps> = props => {
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();
  const isMobile = useBrowserType() === 'mobile';
  const [codeError, setCodeError] = useState<null | PromotionCodeNotFound | PromotionCodeNotApplicable>(null);
  const [creditCardState, setCreditCardState] = useRecoilState(creditCardAtom);
  const { isAdmin } = useRecoilValue(userAtom);
  const searchParams = useLocalSearchParams();
  const { visitReasons } = useVisitReasons();
  const { product } = useStripeProduct();
  const discountCodeTuples: [string, PromotionCode | undefined][] = [
    ['promotionCode', creditCardState.promotionCode],
    ['friendReferralCode', creditCardState.friendReferralCode],
  ];
  const isRecurring = product?.price.type === 'recurring';

  const { checkCode, isLoading } = useCheckCode();
  const {
    discountPrice,
    firstMonthFreeDiscount,
    error: errorCalculatePrice,
    retry: retryCalculatePrice,
    isLoading: isCalculatePriceLoading,
  } = useCalculatePrice(props.product, creditCardState);

  const totalPrice = discountPrice;

  const renewalPrice = formatPriceInCents(
    calculateRenewalPrice(
      props.product,
      discountCodeTuples.map(([, promotionCode]) => promotionCode)
    )
  );

  const discountCodeTuplesRef = useRef(discountCodeTuples);

  discountCodeTuplesRef.current = discountCodeTuples;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    discountCodeTuplesRef.current.forEach(async tuple => {
      const value = tuple[1];

      if (!value || value.state !== 'init') {
        return;
      }

      // Code is entered but not yet loaded - load it
      const result = await checkCode(value.code, tuple);

      if (result?.state === 'not-found' || result?.state === 'not-applicable') {
        setCodeError(result);
        setCreditCardState(prevState => ({ ...prevState, [tuple[0]]: null }));
      }
    });
  }, [checkCode, isLoading, setCreditCardState]);

  // For PCOS membership, give 100% discount. Care Team will manually charge PCOS membership fee
  useEffect(() => {
    if (isAdmin && visitReasons?.find(v => v.code === searchParams.visit_reason)?.isPcos) {
      checkCode('PCOSMEMBERSHIP');
    }
  }, [searchParams.visit_reason, checkCode, isAdmin, visitReasons]);

  if (errorCalculatePrice) {
    return <ErrorMessage error={errorCalculatePrice} onTryAgain={() => retryCalculatePrice()} />;
  }

  const content = (
    <View style={styles.sidePanelContainer}>
      <View style={styles.cartItem}>
        <View style={styles.cartItemImageContainer}>
          <ProductIcon />
        </View>
        <View style={styles.cartItemMain}>
          <Text fontStyle="bold" size="m" numberOfLines={1}>
            {props.product.name}
          </Text>
        </View>
        <Text fontStyle="bold" size="m">
          {formatPriceInCents(props.product.price.amount)}
        </Text>
      </View>
      <CodeEntryForm
        isLoading={isLoading || isCalculatePriceLoading}
        onError={code => setCodeError(code)}
        codeError={codeError}
        onCheckCode={checkCode}
      />
      <View style={styles.enteredCodesContainer} testID="PromotionCodeValues">
        {discountCodeTuples.map(
          ([promotionCodeType, promotionCode]) =>
            promotionCode?.state === 'success' && (
              <View style={styles.enteredCode} key={promotionCode.code}>
                <Text size="s" style={styles.enteredCodeText}>
                  {promotionCode.code}
                </Text>
                <IconButton
                  testID="PromotionCodeRemove"
                  aria-label={t('creditCard.promotion.removeCode', { code: promotionCode.code })}
                  onPress={() => setCreditCardState(prevState => ({ ...prevState, [promotionCodeType]: null }))}
                  style={styles.enteredCodeButton}
                  source="close"
                  size={sizes.SPACING_M}
                  color="secondaryTextDark"
                />
              </View>
            )
        )}
      </View>
      <View style={styles.priceContainer}>
        {discountCodeTuples.map(
          ([promotionCodeType, promotionCode]) =>
            promotionCode &&
            promotionCode.state !== 'not-found' && (
              <LineItem
                key={promotionCode.code}
                size="m"
                title={t(
                  promotionCodeType === 'promotionCode'
                    ? 'creditCard.promotion.discount'
                    : 'creditCard.promotion.referralDiscount'
                )}
                isLoading={promotionCode.state === 'init'}
                amount={
                  promotionCodeType === 'promotionCode' && promotionCode.code === 'firstmonthfree'
                    ? -(firstMonthFreeDiscount ?? 0)
                    : -calculateDiscount(props.product, promotionCode)
                }
              />
            )
        )}
        {discountCodeTuples.some(([, promotionCode]) => promotionCode) && isRecurring && (
          <View style={styles.priceContainerItem}>
            <View style={styles.priceContainerLeftColumn}>
              <Text size="s">
                {t('creditCard.promotion.renewal', {
                  price: renewalPrice,
                })}
              </Text>
            </View>
          </View>
        )}
        <LineItem
          size="xl"
          title={t('creditCard.promotion.total')}
          isLoading={isCalculatePriceLoading}
          amount={totalPrice}
          amountTestID="TotalPrice"
        />
        {isRecurring && (
          <Text size="m">
            {t(`creditCard.${firstMonthFreeDiscount ? 'totalExplanationFirstMonthFree' : 'totalExplanation'}`)}
          </Text>
        )}
      </View>
    </View>
  );

  return isMobile ? props.mobileRender(content, isLoading, !!codeError, totalPrice) : content;
};
